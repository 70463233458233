import React from 'react'
import { navigate } from 'gatsby'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Meta from 'components/meta'
import Wrapper from 'components/wrapper'

export default function NotFoundPage({ location }) {
  return (
    <Wrapper
      location={location}
      variant="page"
      wrapperClass="w-100 h-100 d-flex flex-column justify-content-center"
    >
      <Meta title="Not Found" />
      <Row className="py-3 justify-content-center text-center">
        <Col xs={12} sm={10} md={9} lg={8}>
          <h2>Not Found</h2>
          <p className="fs-5 mb-3">{`Sorry, that link isn't working right now.`}</p>
          <div className="my-3 justify-content-center text-nowrap">
            <Button
              variant="secondary"
              className="mx-1"
              onClick={() => navigate('/')}
            >
              Back home
            </Button>
          </div>
        </Col>
      </Row>
    </Wrapper>
  )
}
